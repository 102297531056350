import react, { useState } from "react"

// Mui
import { Button, Checkbox } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField';

const SearchPanel = () => {
    const [age, setAge] = useState('')

    const handleChange = (event: any) => {
        setAge(event.target.value as string)
    }

    return (
        <div className="flex flex-col">
            <div className="flex gap-3">
                <div className="flex items-center">
                    <Checkbox defaultChecked sx={{ borderRadius: '3rem' }} />
                    <h1 className="font-yekan">یک طرفه</h1>
                </div>
                <div className="flex items-center">
                    <Checkbox sx={{ borderRadius: '3rem' }} />
                    <h1 className="font-yekan">دو طرفه</h1>
                </div>
            </div>

            <div className="flex flex-col gap-2 sm:grid md:grid-cols-2">
                <TextField label="مبدا" variant="outlined" />
                <TextField label="مقصد" variant="outlined" />
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="زمان رفت" sx={{ width: "100%" }} />
                    </LocalizationProvider>
                </div>

                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="زمان برگشت" sx={{ width: "100%" }} />
                    </LocalizationProvider>
                </div>

                <FormControl fullWidth className="col-span-2">
                    <InputLabel>کلاس پروازی</InputLabel>
                    <Select
                        value={age}
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>پرواز اکونومی</MenuItem>
                        <MenuItem value={2}>پرواز پریمیوم</MenuItem>
                        <MenuItem value={3}>پرواز بیزنس</MenuItem>
                        <MenuItem value={4}>پرواز فرست</MenuItem>
                    </Select>
                </FormControl>

            </div>
            <div>
                <Button variant='contained' sx={{ my: 3, width: "100%", py: 1.5, fontSize: 17 }}>جستجو</Button>
            </div>
        </div>
    )
}

export default SearchPanel