import React from 'react'
import { menuItems } from '../utils/data'

const MenuDesktop = () => {
  return (
    <nav>
      <ul className='flex items-center gap-16 font-bold cursor-pointer text-lg'>
        {
            menuItems.map(item => 
                <li key={item.id}>
                    <span>{item.name}</span>
                </li>
            )
        }
      </ul>
    </nav>
  )
}

export default MenuDesktop
