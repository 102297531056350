import react from "react";

//mui
import { Button, Tooltip } from "@mui/material";

const weekDay = [
  { id: "0", day: "شنبه" },
  { id: "1", day: "یکشنبه" },
  { id: "2", day: "دوشنبه" },
  { id: "3", day: "سه شنبه" },
  { id: "4", day: "چهارشنبه" },
  { id: "5", day: "پنجشنبه" },
  { id: "6", day: "جمعه" },
];

const transportTimes = [
  {
    id: 1,
    time: "08:00",
    tours: [
      {
        id: 1,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "مشهد",
        day: "شنبه",
        dayKey: 0,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 2,
        timeStart: "08:00",
        timeEnd: "16:20",
        destination: "تهران",
        day: "دوشنبه",
        dayKey: 2,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 3,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "اصفهان",
        day: "سه شنبه",
        dayKey: 3,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 4,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "شیراز",
        day: "جمعه",
        dayKey: 6,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
    ],
  },
  {
    id: 1,
    time: "12:00",
    tours: [
      {
        id: 1,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "گیلان",
        day: "یکشنبه",
        dayKey: 1,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 2,
        timeStart: "08:00",
        timeEnd: "16:20",
        destination: "تهران",
        day: "دوشنبه",
        dayKey: 2,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 3,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "اصفهان",
        day: "چهارشنبه",
        dayKey: 4,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 4,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "شیراز",
        day: "پنجشنبه",
        dayKey: 5,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
    ],
  },
  {
    id: 1,
    time: "15:00",
    tours: [
      {
        id: 1,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "مشهد",
        day: "شنبه",
        dayKey: 0,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 3,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "اصفهان",
        day: "سه شنبه",
        dayKey: 3,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 4,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "شیراز",
        day: "جمعه",
        dayKey: 6,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 5,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "تهران",
        day: "پنجشنبه",
        dayKey: 5,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
    ],
  },
  {
    id: 1,
    time: "18:00",
    tours: [
      {
        id: 1,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "مشهد",
        day: "شنبه",
        dayKey: 0,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 2,
        timeStart: "08:00",
        timeEnd: "16:20",
        destination: "تهران",
        day: "یکشنبه",
        dayKey: 1,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 3,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "اصفهان",
        day: "سه شنبه",
        dayKey: 3,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 4,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "شیراز",
        day: "جمعه",
        dayKey: 6,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 5,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "شیراز",
        day: "پنجشنبه",
        dayKey: 5,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
    ],
  },
  {
    id: 1,
    time: "20:00",
    tours: [
      {
        id: 1,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "مشهد",
        day: "شنبه",
        dayKey: 0,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 2,
        timeStart: "08:00",
        timeEnd: "16:20",
        destination: "تهران",
        day: "دوشنبه",
        dayKey: 2,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 3,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "اصفهان",
        day: "چهارشنبه",
        dayKey: 4,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 4,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "شیراز",
        day: "پنجشنبه",
        dayKey: 5,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
    ],
  },
  {
    id: 1,
    time: "23:30",
    tours: [
      {
        id: 1,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "مشهد",
        day: "یکشنبه",
        dayKey: 1,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 2,
        timeStart: "08:00",
        timeEnd: "16:20",
        destination: "تهران",
        day: "دوشنبه",
        dayKey: 2,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 3,
        timeStart: "08:00",
        timeEnd: "16:30",
        destination: "اصفهان",
        day: "چهارشنبه",
        dayKey: 4,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
      {
        id: 4,
        timeStart: "08:00",
        timeEnd: "20:15",
        destination: "شیراز",
        day: "جمعه",
        dayKey: 6,
        description:
          "این تور پرواز مستقیم به سمت مشهد است و رفت و برگشت ندارد و همراه با هواپیما Vip است",
      },
    ],
  },
];

const TransportTable = () => {
  return (
    <section className="bg-slate-800 w-full overflow-scroll">
      <div className="flex items-center justify-center flex-col max-w-5xl my-0 mx-auto">
        <h1 className="text-5xl font-extrabold text-slate-50 py-8">
          ساعت های پرواز
        </h1>
        <table
          className="bg-[#2457DB] text-white rounded-2xl mb-8 shadow-lg"
          border={1}
          cellPadding={5}
          cellSpacing={5}
        >
          <thead>
            <tr className="h-10">
              <th className="px-16 font-light border-b border-slate-400">
                ساعت
              </th>
              {weekDay.map((item) => (
                <th className="px-16 font-light border-b border-slate-400">
                  {item.day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transportTimes.map((transport) => (
              <tr key={transport.id}>
                <td className="text-center p-2">
                  {transport.time}
                </td>
                {weekDay.map((item) => (
                  <td id={item.id} className="text-center">
                    {transport.tours.map((tour) =>
                      +tour.dayKey === +item.id ? (
                        <Tooltip
                          title={
                            <div className="p-2 flex flex-col">
                              <div className="flex gap-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" className="bi bi-airplane" viewBox="0 0 16 16">
                                  <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Zm.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1c-.213 0-.458.158-.678.599Z"/>
                                </svg>
                                <h6 style={{fontSize: '1rem'}}>{tour.description}</h6>
                              </div>
                              <Button sx={{backgroundColor: '#4776ED' , color: '#fff' , margin: '1rem 0 0 0' , ":hover":{backgroundColor: '#4776ED'}}}>دیدن جزئیات</Button>
                            </div>
                          }
                          placement="top"
                          arrow
                          sx={{ height: "10rem" }}
                        >
                          <div className="bg-slate-100 text-slate-800 rounded-lg py-1">
                            <h4>
                              {tour.timeStart} - {tour.timeEnd}
                            </h4>
                            <h2>تور {tour.destination}</h2>
                          </div>
                        </Tooltip>
                      ) : null
                    )}
                  </td>
                ))}

                {/* {console.log(thElems)} */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TransportTable;

// {araye.slice(0, 0).map((item2) => (
//   <>
//     <td>1</td>
//   </>
// ))}

// {transportTimes[0].tours.map((item1) => (
//   <td>2</td>
// ))}

{
  /* {transport.tours.map((tour) =>
                  tour.day === thElem?.current?.id ? (
                    <td
                      key={transport.id}
                      className="text-center p-2 border border-slate-400"
                    >
                      <div className="bg-slate-100 text-slate-800 rounded-lg py-1">
                        <h4>
                          {tour.timeStart} - {tour.timeEnd}
                        </h4>
                        <h2>تور {tour.destination}</h2>
                      </div>
                    </td>
                  ) : (
                    <td
                      key={transport.id}
                      className="text-center p-2 border border-slate-400"
                    ></td>
                  )
                )} */
}
