import React from "react";

// Datas
import { tourData } from "../utils/data";

// Components
import TourCard from "./TourCard";

const ExternalTour = () => {

  return (
    <section className="flex gap-4 items-center justify-between flex-col max-w-7xl mx-auto my-6 overflow-hidden">
      <h1 className="text-5xl font-extrabold text-slate-800">تور های خارجی</h1>
      <div className="flex gap-4 flex-wrap justify-center">
        {tourData.map((tour) => (
          <TourCard data={tour} />
        ))}
      </div>
    </section>
  );
};

export default ExternalTour;
