import React from "react";
import { blogType } from "../types/type";

const Blog = (props: any) => {
  const {image , title , description} = props.data
  return (
    <div className="bg-slate-200 rounded-xl border border-slate-500 w-full overflow-hidden">
        <img src={image} alt="blog" className="w-full object-cover" />
        <h1 className="text-xl p-4 text-slate-800">{title}</h1>
        <p className="px-4 pb-3 text-slate-700 font-bold text-justify">{description}</p>
    </div>
  );
};

export default Blog;