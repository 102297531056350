import React from "react";
import SearchBox from "./SearchBox";

const Landing = () => {
    return (
        <section className="landing mb-14 sm:mb-0">
            <div className="flex items-center justify-center flex-col py-8 text-white gap-2">
                <h1 className="font-extrabold text-4xl text-center sm:text-right">خرید اینترنتی بلیط هواپیما</h1>
                <h3 className="text-xl font-light">هر جای ایران , همه جای دنیا</h3>
            </div>
            <div className="mx-4">
                <SearchBox />
            </div>
        </section>
    )
}

export default Landing