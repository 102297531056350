import * as React from 'react'
import { Listbox } from '@headlessui/react'
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// Components
import SearchPanel from '../shared/SearchPanel'


interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const SearchBox = () => {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const TabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className='bg-white rounded-2xl px-4 w-full pt-1 max-w-3xl my-0 mx-auto'>
      <div className="mt-6 max-w-3xl my-0 mx-auto">
        <div className='w-full bg-white'>
        <AppBar position="static" sx={{borderRadius: "0.4rem" , overflow: "hidden" }}>
          <Tabs className="bg-white" value={value} onChange={TabChange}>
            <Tab label="پرواز داخلی" sx={{fontWeight: '900'}}/>
            <Tab label="پرواز خارجی" sx={{fontWeight: '900'}}/>
          </Tabs>
        </AppBar>
        </div>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <SearchPanel />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <SearchPanel />
        </TabPanel>
      </div>
    </div>
  )
}

export default SearchBox
