import React from "react";

const VisaCard = (props: any) => {
  console.log(props);

  return (
    <div className="visaBox relative overflow-hidden">
      <div className="visaBox relative overflow-hidden">
        <img src={props.data.image} alt="visa" className="brightness-75" />
        <h5 className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-2xl text-white">
          {props.data.title}
        </h5>
        <p className="absolute top-0 text-white z-10">{props.data.description}</p>
      </div>
    </div>
  );
};

export default VisaCard;
