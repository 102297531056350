import React from "react";

//Mui
import { Button } from "@mui/material";

const TourCard = (props: any) => {
  return (
    <div className="bg-slate-100 w-80 p-4 flex flex-col gap-3 border border-slate-400 rounded-xl shadow-lg flex-wrap">
      <div className="rounded-md overflow-hidden shadow-lg">
        <img src={props.data.image} alt="tour" />
      </div>
      <h1 className="font-extrabold text-xl text-slate-800">
        {props.data.location}
      </h1>
      <h3 className="font-extrabold text-md text-slate-800 flex items-center gap-1">
        <span>مدت سفر :</span>
        <span>{props.data.time}</span>
      </h3>
      <h3 className="font-bold text-lg text-slate-800 flex items-center gap-1">
        <span>قیمت :</span>
        <span>{props.data.price}</span>
      </h3>
      <Button sx={{ bgcolor: "#1e293b", color: "#fff" , ":hover": {backgroundColor: '#1e293b'} }}>مشاهده تور</Button>
    </div>
  );
};

export default TourCard;
