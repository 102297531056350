import react from "react"

// Mui
import { Tooltip } from "@mui/material"


const Suggestion = () => {
    return (
        <section className="flex items-center justify-center flex-col max-w-5xl my-3 mx-auto">
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-2 my-4 justify-center">
                <div className="flex flex-col py-4 px-2 gap-2 items-center bg-slate-200 shadow-2xl w-36 border border-slate-400 rounded-lg text-center">
                    <div className="bg-slate-700 p-4 rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-11 h-11 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div>
                        <Tooltip title="اگه راضی نبودید تا 15 روز میتونید انصراف بدید">
                            <h1>تضمین بازگشت وجه</h1>
                        </Tooltip>
                    </div>
                </div>

                <div className="flex flex-col py-4 px-2 gap-2 items-center bg-slate-200 shadow-2xl w-36 border border-slate-400 rounded-lg text-center">
                    <div className="bg-slate-700 p-4 rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-11 h-11 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                    </div>
                    <div>
                        <Tooltip title="اگه راضی نبودید تا 15 روز میتونید انصراف بدید">
                            <h1>تضمین کیفیت</h1>
                        </Tooltip>
                    </div>
                </div>

                <div className="flex flex-col py-4 px-2 gap-2 items-center bg-slate-200 shadow-2xl w-36 border border-slate-400 rounded-lg text-center">
                    <div className="bg-slate-700 p-4 gap-2 rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-11 h-11 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                        </svg>

                    </div>
                    <div>
                        <Tooltip title="اگه راضی نبودید تا 15 روز میتونید انصراف بدید">
                            <h1>پشتیبانی</h1>
                        </Tooltip>
                    </div>
                </div>

                <div className="flex flex-col py-4 px-2 gap-2 items-center bg-slate-200 shadow-2xl w-36 border border-slate-400 rounded-lg text-center">
                    <div className="bg-slate-700 p-4 rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-11 h-11 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div>
                        <Tooltip title="اگه راضی نبودید تا 15 روز میتونید انصراف بدید">
                            <h1>زمان بندی مناسب</h1>
                        </Tooltip>
                    </div>
                </div>

                <div className="flex flex-col py-4 px-2 gap-2 items-center bg-slate-200 shadow-2xl w-36 border border-slate-400 rounded-lg text-center">
                    <div className="bg-slate-700 p-4 rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="w-11 h-11 text-white">
                            <path stroke="currentColor" stroke-width="1.5" d="M3.353 8.95A7.511 7.511 0 0 1 8.95 3.353c2.006-.47 4.094-.47 6.1 0a7.511 7.511 0 0 1 5.597 5.597c.47 2.006.47 4.094 0 6.1a7.511 7.511 0 0 1-5.597 5.597c-2.006.47-4.094.47-6.1 0a7.511 7.511 0 0 1-5.597-5.597 13.354 13.354 0 0 1 0-6.1Z"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m8 9.5 2.078 1.732a1 1 0 0 1 0 1.536L8 14.5"></path>
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="M12.5 14.5H16"></path>
                        </svg>
                    </div>
                    <div>
                        <Tooltip title="اگه راضی نبودید تا 15 روز میتونید انصراف بدید">
                            <h1>تضمین بازگشت وجه</h1>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Suggestion