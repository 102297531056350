import React from "react";
import { visaData } from "../utils/data";
import VisaCard from "./VisaCard";

const Visa = () => {
  return (
    <section className="flex items-center justify-center flex-col max-w-5xl my-0 mx-auto">
      <h1 className="text-5xl font-extrabold text-slate-800">ویزا</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 px-4 justify-center">
        {visaData.map((visa) => <VisaCard data={visa}/>)}
      </div>
    </section>
  );
};

export default Visa;
