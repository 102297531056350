import React from 'react'
//Components
import Navbar from '../components/Navbar'
import Home from '../components/Home'

function App() {
  return (
    <div className="flex flex-col">
      <Navbar />
      <Home/>
    </div>
  )
}

export default App
