import React from 'react'

//Mui
import { Box, Drawer, Button, List, Divider, ListItem, MenuIcon } from '../utils/Mui'

const MenuMobile = () => {
  const [state, setState] = React.useState({ right: false })

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setState({ right: open })
  }

  const list = () => (
    <div onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <h1>Saman</h1>
      <List></List>
    </div>
  )

  return (
    <div>
      <>
        <Button >
          <MenuIcon fontSize='large' />
        </Button>
        <Drawer
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </>
    </div>
  )
}

export default MenuMobile
