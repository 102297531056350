import { blogType, menuType, tourType, visaType } from "../types/type";

// Mui
import { BookIcon, FlightIcon, HomeIcon, InfoIcon, TourIcon } from "./Mui";

export const menuItems = <menuType[]>[
  { id: 1, name: "خانه", icon: HomeIcon },
  { id: 2, name: "تور", icon: TourIcon },
  { id: 3, name: "خدمات ویزا", icon: FlightIcon },
  { id: 4, name: "وبلاگ", icon: BookIcon },
  { id: 4, name: "درباره ما", icon: InfoIcon },
];

export const tourData = <tourType[]>[
  {
    id: 1,
    image:
      "https://cdn.setarehvanak.com/media/tour/74/ayasofiya-min1548596961.jpg",
    location: "استانبول",
    time: "7 شب",
    price: "12,100,000",
  },
  {
    id: 2,
    image:
      "https://cdn.setarehvanak.com/media/tour/241/tumb-min1569745595.jpg",
    location: "تور بمبئی",
    time: "2 شب",
    price: "22,000,000",
  },
  {
    id: 3,
    image:
      "https://cdn.setarehvanak.com/media/tour/266/tumb-min1576407251.jpg",
    location: "تور سریلانکا",
    time: "15 شب",
    price: "8,500,000",
  },
];

export const visaData = <visaType[]>[
  {
    id: 1,
    title: "ویزای دبی",
    image:
      "https://cdn.setarehvanak.com/assets/src/images/index/dubay-visa.png",
    description:
      "ایرانیان برای ورود به دبی بایستی ویزا دریافت کنند. ویزاهای توریستی دبی با توجه به درخواست متقاضی به صورت 14 روزه، یک ماهه و 3 ماهه صادر می‌شود. آژانس هواپیمایی آسان تور به عنوان کارگزار رسمی ویزای دبی بهترین و کامل‌ترین خدمات را در زمینه دریافت ویزای توریستی دبی ارائه می‌دهد.",
  },
  {
    id: 2,
    title: "ویزای کانادا",
    image:
      "https://cdn.setarehvanak.com/assets/src/images/index/canada-visa.png",
    description:
      "ایرانیان برای ورود به دبی بایستی ویزا دریافت کنند. ویزاهای توریستی دبی با توجه به درخواست متقاضی به صورت 14 روزه، یک ماهه و 3 ماهه صادر می‌شود. آژانس هواپیمایی آسان تور به عنوان کارگزار رسمی ویزای دبی بهترین و کامل‌ترین خدمات را در زمینه دریافت ویزای توریستی دبی ارائه می‌دهد.",
  },
  {
    id: 3,
    title: "ویزای شنگن",
    image:
      "https://cdn.setarehvanak.com/assets/src/images/index/shengen-visa.png",
    description:
      "ایرانیان برای ورود به دبی بایستی ویزا دریافت کنند. ویزاهای توریستی دبی با توجه به درخواست متقاضی به صورت 14 روزه، یک ماهه و 3 ماهه صادر می‌شود. آژانس هواپیمایی آسان تور به عنوان کارگزار رسمی ویزای دبی بهترین و کامل‌ترین خدمات را در زمینه دریافت ویزای توریستی دبی ارائه می‌دهد.",
  },
];

export const blogsData = <blogType[]>[
  {
    id: 1,
    image:
      "https://setarehvanak.com/blog/wp-content/uploads/2023/05/little-305x149.jpg",
    title: "مهمترین قوانین سفر به مالدیو که نمی دانید !",
    description: "مسافرت به مالدیو می‌تواند یکی از بهترین انتخاب‌های هرکسی برای گذراندن تعطیلات باشد. البته سفر به این مقصد ناشناخته برای بسیاری از مسافران پر از سوال و ابهام است و امکان دارد بسیاری از افراد ندانند قوانین مسافرت به مالدیو چیست...",
  },
  {
    id: 2,
    image:
      "https://setarehvanak.com/blog/wp-content/uploads/2023/05/little_6_11zon-2-305x149.webp",
    title: "آشنایی به بهترین پیست های دبی ؟",
    description: "پیست اسکی در دبی با سایر پیست‌های اسکی دنیا تفاوت‌های بارزی دارد. تا چند سال پیش اگر می‌شنیدیم کسی برای اسکی به دبی می‌رود شاید برایمان باورناپذیر بود؛ اما امروزه با پیشرفت‌های فناوری هر کار غیرممکنی، ممکن به نظر می‌رسد.",
  },
  {
    id: 3,
    image:
      "https://setarehvanak.com/blog/wp-content/uploads/2023/05/little_4_11zon-1-305x149.jpg",
    title: "راهنمای خرید بلیط موناکو",
    description: "شهر موناکو یکی از کوچک‌ترین کشورهای جهان است و در جنوب فرانسه واقع شده است. این شهر وسعت کمی دارد، حدود ۲ کیلومتر مربع و جمعیتی در حدود ۳۸ هزار نفر دارد. اگرچه موناکو کشور کوچکی است،",
  },
];
