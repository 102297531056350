import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app/App'
import { createTheme, ThemeProvider } from '@mui/material'
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const theme = createTheme({
  palette: {
    primary: {
      main: "#1D3248",
      light: "#f8f8f8"
    }
  },
  direction: "rtl" ,
  typography: {
    fontFamily: ''
  }
})

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

root.render(
  <React.StrictMode>
    <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>,
)
