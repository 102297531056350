import React from "react";
import { blogsData} from "../utils/data";
import Blog from "./Blog";

const Blogs = () => {
  return (
    <section className="flex gap-4 items-center justify-between flex-col max-w-5xl mx-auto my-6">
      <h1 className="text-5xl font-extrabold text-slate-800">بلاگ ها</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 px-4">
        {
            blogsData.map(blog => <Blog data={blog}/>)
        }
      </div>
    </section>
  );
};

export default Blogs;
