import React from "react";

// Comonents
import Landing from "./Landing";
import Suggestion from "./Suggestion";
import ExternalTour from "./ExternalTour";
import Visa from "./Visa";
import TransportTable from "./TransportTable";
import Blogs from "./Blogs";
import Footer from "./Footer";

const Home = () => {
    return (
        <main className="flex flex-col gap-4">
            <Landing />
            <Suggestion />
            <ExternalTour/>
            <Visa/>
            <TransportTable/>
            <Blogs/>
            <Footer/>
        </main>
    )
}

export default Home