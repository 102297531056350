import React from "react";

const Footer = (props: any) => {
  return (
    <footer className="bg-slate-900">
      <div className="max-w-[95rem] grid grid-cols-1 sm:grid-cols-2 sm:text-right lg:grid-cols-4 justify-center text-center gap-8 text-white p-8 mx-auto mt-0">
        <div className="flex items-center lg:items-start justify-center flex-col">
        <div className="h-12 flex items-center justify-center pb-10">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-airplane-engines" viewBox="0 0 16 16">
            <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.347 4.347 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0ZM7 3c0-.432.11-.979.322-1.401C7.542 1.159 7.787 1 8 1c.213 0 .458.158.678.599C8.889 2.02 9 2.569 9 3v4a.5.5 0 0 0 .276.447l5.448 2.724a.5.5 0 0 1 .276.447v.792l-5.418-.903a.5.5 0 0 0-.575.41l-.5 3a.5.5 0 0 0 .14.437l.646.646H6.707l.647-.646a.5.5 0 0 0 .14-.436l-.5-3a.5.5 0 0 0-.576-.411L1 11.41v-.792a.5.5 0 0 1 .276-.447l5.448-2.724A.5.5 0 0 0 7 7V3Z"/>
          </svg>
          </div>
          <div>
            <ul className="flex flex-col gap-4">
              <li className="flex items-center gap-3 justify-center lg:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  height={16}
                  width={16}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
                <span>88883650</span>
              </li>
              <li className="flex items-center gap-3 justify-center lg:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-headset"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                </svg>
                <span>09121235667</span>
                <span>پشتیبانی خرید آنلاین</span>
              </li>
              <li className="flex items-center gap-3 justify-center lg:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
                <span>itteamiran@gmail.com</span>
              </li>
              <li className="flex items-center gap-3  justify-center lg:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-geo-alt"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                <span>تهران , میدان ونک , خیابان حقانی , نبش گاندی شمالی</span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h1>تور های آسان تور</h1>
          <ul className="flex flex-col gap-3 pt-3">
            <li>تور اروپا</li>
            <li>تور آفریقا</li>
            <li>تور آلمان</li>
            <li>تور ایران</li>
          </ul>
        </div>
        <div>
          <h1>خدمات ویزا آسان تور</h1>
          <ul className="flex flex-col gap-3 pt-3">
            <li>وقت سفارت</li>
            <li>ویزای کانادا</li>
            <li>ویزای اسپانیا</li>
            <li>ویزای شینگنن</li>
            <li>ویزا دبی</li>
          </ul>
        </div>
        <div>
          <h1>درباره آسان تور</h1>
          <p className="leading-8 text-justify pt-2">
            آژانس هواپیمایی آسان تور با بیش از سه دهه تجربه، یکی از بهترین‌ها
            در زمینه فروش بلیط هواپیما است. این شرکت همچنین مجری مستقیم بسیاری
            از تورهای داخلی و خارجی از جمله تور اسپانیا و تور آفریقای جنوبی است.
            به علاوه ما در زمینه اخذ ویزای کانادا و ویزای انگلیس نیز خدمات ارائه
            می‌کنیم.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
